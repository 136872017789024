import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Glasses from "./glasses";
import { rhythm } from "../utils/typography";
import * as styles from "./header.module.css";

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`;

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "folded-glasses.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      site {
        siteMetadata {
          author
        }
      }
    }
  `);

  const author = data.site.siteMetadata.author;

  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(3)} ${rhythm(3 / 4)} ${rhythm(3 / 4)}`,
      }}
    >
      <header className={styles.header}>
        {location && location.pathname === rootPath ? (
          <Glasses
            alt={`${author}'s Folded Glasses`}
            title={`${author}'s Folded Glasses`}
          />
        ) : (
          <h3>{title}</h3>
        )}
      </header>
      <main>{children}</main>
    </div>
  );
};

export default Layout;
