import React from "react";

const Glasses = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 700.000000 181.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0.000000,181.000000) scale(0.100000,-0.100000)">
        <path
          d="M1310 1670 c-268 -18 -398 -31 -585 -61 -265 -41 -245 -36 -245 -70
0 -16 -6 -38 -12 -49 -10 -16 -10 -24 0 -40 14 -24 16 -166 2 -175 -15 -9 -12
-45 5 -51 10 -4 15 -20 15 -46 0 -36 3 -40 37 -55 21 -8 68 -20 104 -25 l67
-10 6 -52 c23 -174 80 -374 146 -513 65 -133 171 -207 392 -273 264 -79 729
-130 987 -109 390 31 584 97 696 237 132 166 255 523 255 744 l0 46 48 11
c130 30 389 43 499 26 121 -19 170 -29 184 -36 12 -6 17 -30 22 -101 16 -243
119 -525 250 -687 90 -111 174 -155 375 -197 235 -48 454 -58 727 -35 442 39
761 131 892 258 84 82 171 309 214 557 12 67 22 122 23 123 1 1 33 7 71 13 38
6 84 18 102 27 29 13 33 20 33 53 0 24 5 40 15 44 8 3 15 12 15 19 0 8 -7 20
-15 27 -22 18 -22 168 0 176 17 7 20 40 5 49 -5 3 -10 21 -10 40 0 39 22 33
-245 74 -547 85 -1270 94 -1777 21 -194 -28 -412 -72 -625 -127 -259 -66 -295
-72 -433 -72 -132 0 -165 5 -380 62 -311 82 -657 144 -930 167 -170 14 -764
21 -930 10z m860 -105 c377 -32 660 -79 771 -128 72 -32 128 -81 153 -137 32
-71 35 -263 6 -403 -32 -162 -69 -257 -153 -401 -118 -200 -260 -267 -627
-297 -526 -43 -1182 73 -1343 237 -107 108 -204 425 -223 724 -17 269 43 323
436 385 205 33 706 43 980 20z m3640 -6 c108 -12 321 -52 381 -73 67 -23 120
-67 142 -116 17 -38 20 -64 19 -165 -2 -147 -14 -235 -52 -390 -52 -209 -120
-345 -207 -411 -59 -45 -173 -89 -330 -129 -262 -65 -521 -90 -833 -82 -361
10 -580 67 -678 176 -127 142 -228 382 -263 628 -14 102 1 256 32 320 61 127
288 192 854 244 164 15 258 17 520 13 176 -2 363 -9 415 -15z"
        />
        <path
          d="M916 1445 c-53 -19 -104 -71 -117 -119 -10 -38 -9 -209 1 -219 3 -3
113 -15 245 -26 132 -12 284 -26 338 -31 54 -6 120 -10 147 -10 27 0 51 -4 54
-8 3 -5 -168 -24 -380 -42 -376 -34 -384 -35 -384 -56 0 -23 44 -202 52 -210
13 -12 2154 116 2178 131 25 15 46 355 23 375 -5 4 -84 15 -178 24 -398 40
-1753 183 -1900 201 -22 2 -57 -2 -79 -10z"
        />
        <path
          d="M5990 1449 c-87 -10 -1639 -165 -1812 -181 -102 -9 -139 -16 -143
-26 -17 -44 -17 -194 0 -289 10 -56 21 -105 24 -111 3 -5 107 -16 231 -26 404
-29 1258 -85 1550 -101 157 -8 307 -17 333 -19 48 -5 49 -5 64 31 18 44 48
181 42 188 -5 4 -911 95 -953 95 -11 0 -17 2 -15 5 3 2 90 11 194 20 511 42
800 66 802 68 1 1 5 36 9 77 10 113 -7 181 -57 223 -64 55 -119 65 -269 46z"
        />
        <path
          d="M3440 1169 c-119 -7 -220 -26 -220 -40 0 -31 -22 -209 -30 -236 -6
-24 -5 -33 4 -33 7 0 70 4 142 10 103 8 174 6 354 -8 124 -10 228 -17 231 -16
3 2 0 31 -7 64 -7 33 -15 99 -19 145 l-7 84 -52 11 c-28 6 -89 13 -136 16 -47
3 -101 6 -120 7 -19 2 -82 0 -140 -4z"
        />
        <path
          d="M390 918 c-238 -22 -243 -23 -256 -95 -7 -38 9 -105 29 -121 11 -8
555 4 566 14 3 3 -26 135 -46 207 -5 21 -7 21 -293 -5z"
        />
        <path
          d="M6410 858 c-6 -24 -18 -69 -27 -101 -9 -32 -13 -60 -9 -64 3 -3 79
-10 168 -14 90 -4 191 -11 225 -14 61 -5 63 -5 77 23 25 48 29 95 12 136 -8
20 -21 38 -28 41 -16 5 -334 35 -376 35 -28 0 -32 -4 -42 -42z"
        />
      </g>
    </svg>
  );
};

export default Glasses;
